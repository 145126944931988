import * as React from 'react';
import { Table, Collapse } from 'antd';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import mastercard from 'assets/img/mastercard.png';
import maestro from 'assets/img/maestro.png';
import visa from 'assets/img/visa.png';
import mir from 'assets/img/mirpay.png';
import arrow from 'assets/img/payarrow.png';
import fileIcon from 'assets/img/fileIcon.png';
import addressIcon1 from 'assets/img/address_blue_icon.png';
// import addressIcon2 from 'assets/img/address_red_icon.png';
import style from './style.module.css';

import comepay from 'assets/test/comepay.png';
import elecsnet from 'assets/test/elecsnet.png';
import korona from 'assets/test/korona.png';
import leader from 'assets/test/leader.png';
import qiwi from 'assets/test/qiwi.png';
import quickpay from 'assets/test/quickpay.png';
import metro_bot from "../../../assets/img/mosmetro_sign_bot.svg";
import metro_otr from "../../../assets/img/mosmetro_sign_otr.svg";
// import addressIcon1 from 'assets/img/metro.svg';

const Panel = Collapse.Panel;

export interface Methods {}

export class Methods extends React.Component<Methods, any> {
  static defaultProps: Partial<Methods> = {};

  constructor(props: Methods, context?: any) {
    super(props, context);
    this.state = {
      autoPay: !true,
      map: '',
      map2: ''
    };
  }

  componentDidMount(): void {
    import("../../components/Map")
        .then(({Map}) => {
          console.log('load Map');
          this.setState({
            map: Map
          })
        })
        .catch(err => {
          console.log(err)
          // Handle failure
        });
    import("../../components/Map2")
        .then(({Map2}) => {
          console.log('load Map');
          this.setState({
            map2: Map2
          })
        })
        .catch(err => {
          console.log(err)
          // Handle failure
        });
  }

  toggleData = (val: boolean) => {
    this.setState({
      autoPay: val
    });
  };

  renderRow = (record: any, i: any) => (i % 2 !== 0 ? style.greenBg : '');

  text1 = (
    <div className={style.text}>
      <p>
        Полное наименование: Общество с ограниченной
        ответственностью Микрокредитная
        компания «КАПЕЛЛА»
        <br />
        ИНН: 2465156710
        <br />
        КПП: 246501001
        <br />
        Расчетный счет: 40701810831000000176 в Дополнительный офис № 8646/070 Сбербанка России г. Красноярск
        <br />
        БИК: 040407627
        <br />
        Корр. счет: 30101810800000000627
        <br />
        Назначение платежа: ОПЛАТА ЗА ____(ФИО)___ПО ДОГОВОРУ № __________ от «___»
        _________________ 2019г.
        <br />
        НДС не облагается.
      </p>
      <a href={'/files/Карточка%20предприятия.pdf'}
         target="_blank"
         rel="noopener noreferrer">
        <img src={fileIcon} alt="" />
        <span>Скачать реквизиты</span>
      </a>
    </div>
  );

  text2 = (
    <div className={style.text}>
      <p>
        Полное наименование: Общество с ограниченной ответственностью Микрокредитная компания «НАРОДНАЯ ОПОРА»
        <br />
        ИНН: 2460221877
        <br />
        КПП: 246501001
        <br />
        Расчетный счет: 40701810031000000125 Отделение № 8646 Сбербанка России г.
        Красноярск
        <br />
        БИК: 040407627
        <br />
        Корр. счет: 30101810800000000627
        <br />
        Назначение платежа: ОПЛАТА ЗА ____(ФИО)___ПО ДОГОВОРУ № __________ от «___»
        _________________ 2020г.
        <br />
        НДС не облагается.
      </p>
      <a href={'/files/Карточка%20предприятия2.pdf'}
         target="_blank"
         rel="noopener noreferrer">
        <img src={fileIcon} alt="" />
        <span>Скачать реквизиты</span>
      </a>
    </div>
  );

  dataSource: any[] = [
    {
      key: '1',
      logo: comepay,
      region: 'Москва, Санкт-Петербург',
      commission: '0',
      link: '#'
    },
    {
      key: '2',
      logo: elecsnet,
      region: 'Москва, Санкт-Петербург',
      commission: '0',
      link: '#'
    },
    {
      key: '3',
      logo: qiwi,
      region: 'Москва, Санкт-Петербург',
      commission: '0',
      link: '#'
    },
    {
      key: '4',
      logo: quickpay,
      region: 'Москва',
      commission: '0',
      link: '#'
    },
    {
      key: '5',
      logo: leader,
      region: 'Санкт-Петербург',
      commission: '0',
      link: '#'
    },
    {
      key: '6',
      logo: korona,
      region: 'Москва, Санкт-Петербург',
      commission: '1',
      link: '#'
    }
  ];

  columns: any[] = [
    {
      title: 'Платежная система',
      dataIndex: 'logo',
      key: 'logo',
      width: '25%',
      align: 'center',
      render: (text: any, { logo }: any) => <img src={logo} alt="" />
    },
    {
      title: 'Регион обслуживания',
      dataIndex: 'region',
      key: 'region',
      width: '30%'
    },
    {
      title: 'Комиссия',
      dataIndex: 'commission',
      key: 'commission',
      width: '20%',
      align: 'center',
      render: (text: any, { commission }: any) => <span>{commission}%</span>
    },
    {
      title: 'Инструкция',
      dataIndex: 'link',
      key: 'link',
      width: '24%',
      align: 'center',
      render: (text: any, { link }: any) => (
        <a href={link} className={'disabled'}>
          Смотреть
        </a>
      )
    }
  ];

  columns2: any[] = [
    {
      title: 'Платежная система',
      dataIndex: 'logo',
      key: 'logo',
      width: '25%',
      align: 'center',
      render: (text: any, { logo }: any) => <img src={logo} alt="" />
    },
    {
      title: 'Регион обслуживания',
      dataIndex: 'region',
      key: 'region',
      width: '37%'
    },
    {
      title: 'Комиссия',
      dataIndex: 'commission',
      key: 'commission',
      width: '20%',
      align: 'center',
      render: (text: any, { commission }: any) => <span>{commission}%</span>
    },
    {
      title: 'Инструкция',
      dataIndex: 'link',
      key: 'link',
      width: '18%',
      align: 'center',
      render: (text: any, { link }: any) => <a href={link}>Смотреть</a>
    }
  ];

  render() {
    let paymentClass = style.payment;
    let Map = this.state.map || (() => <div/>);
    let Map2 = this.state.map2 || (() => <div/>);
    let text = this.text1;

    if (this.state.autoPay) {
      paymentClass = classnames(style.payment, style.autoPayment);
      text = this.text2;
    }

    /*
    * <div onClick={() => this.toggleData(true)} className={'disabled'}>ООО ЛОМБАРД «КРЕДИТКАР»</div>
    * */

    return (
      <div className={style.Methods}>
        <section className={style.bigLink}>
          <Link to={'/payment'}>
            <div>
              <div>
                <h2>
                  <b>Онлайн оплата</b>
                </h2>
                <h2>с банковской карты любого банка</h2>
              </div>
              <div>
                <img src={mastercard} alt="Mastercard" />
                <img src={maestro} alt="Maestro" />
                <img src={visa} alt="VISA" />
                <img src={mir} alt="МИР" />
              </div>
            </div>
            <img src={arrow} alt="" />
          </Link>
        </section>
        <Collapse>
          <Panel header={<h1>Банковский перевод</h1>} key="1">
            <section className={paymentClass}>
              <p>
                Вы можете перевести денежные средства в счет оплаты по договору займа посредством
                банковского перевода. При использовании банковского перевода предварительно уточните
                срок проведения платежа Вашим банком. Согласно договору займа, датой платежа
                является дата зачисления денежных средств на расчетный счет Кредитора. Также
                необходимо учитывать, что банки взимают комиссии за перевод. Перед совершением
                платежа внимательно ознакомьтесь с тарифами и условиями Вашего банка.
              </p>
              <h2>
                <b>Реквизиты платежа:</b>
              </h2>
              <article>
                <div onClick={() => this.toggleData(false)}>ООО МКК "КАПЕЛЛА"</div>
                <div onClick={() => this.toggleData(true)}>ООО МКК "НАРОДНАЯ ОПОРА"</div>
              </article>
              {text}
            </section>
          </Panel>
          <Panel header={<h1>Оплата в офисе наличными через кассу</h1>} key="2">
            <section className={style.contacts}>
              <h2>Санкт-Петербург</h2>
              <Map />
              <div className={style.address}>
                <div>
                  <p>2-й Муринский проспект, дом 36. стр.1 , пом.181Н</p>
                  <p>
                    <img src={addressIcon1} alt="" />
                      Площадь мужества
                  </p>
                </div>
                <article>
                  <div>
                    <p>График работы:</p>
                    <p>Пн-Пт, с 10.00 до 19.00</p>
                  </div>
                  <a href="tel:+78006009580">тел. 8-800-600-95-80</a>
                </article>
              </div>
              {/*<div className={style.addressBorder}></div>*/}
              {/*<h2>Москва</h2>*/}
              {/*<Map2 />*/}
              {/*<div className={style.address}>*/}
              {/*  <div>*/}
              {/*    <p>Берёзовая аллея, 5Ас5</p>*/}
              {/*    <p>*/}
              {/*      <img src={metro_bot} alt="Ботанический сад" /> Ботанический сад — 1300 м<br />*/}
              {/*      <img src={metro_otr} alt="Отрадное" />Отрадное — 2300 м*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*  <article>*/}
              {/*    <div>*/}
              {/*      <p>График работы:</p>*/}
              {/*      <p>Пн-Пт, с 10.00 до 19.00</p>*/}
              {/*    </div>*/}
              {/*    <a href="tel:+78006009580">тел. 8-800-600-95-80</a>*/}
              {/*  </article>*/}
              {/*</div>*/}
            </section>
          </Panel>
          <Panel className={'disabled'} header={<h1>Платежная система CONTACT</h1>} key="3">
            <section className={style.system}>
              <p>
                Вы можете внести денежные средства с помощью платежной системы CONTACT на всей
                территории РФ.
              </p>
              <h2>
                <b>Для внесения платежа необходимо:</b>
              </h2>
              <ul>
                <li>
                  Выбрать пункт оплаты (<a href={'#'}>пункты приема платежей</a>)
                </li>
                <li>Прийти в выбранный пункт CONTACT</li>
                <li>
                  Сообщить операционисту, что Вы хотите перевести денежные средства по системе
                  CONTACT в счет оплаты займа, полученного в компании ООО «МКК «ОПЛОТ»
                </li>
                <li>
                  Предъявить документ, удостоверяющий личность, сообщить номер договора и ФИО
                  Заемщика, оплатить и получить квитанцию об оплате услуги.
                </li>
              </ul>
              <p>
                Копии всех платежных документов необходимо сохранять до окончания срока действия
                договора займа. При осуществлении перевода внимательно проверяйте реквизиты и
                наименование получателя.
              </p>
            </section>
          </Panel>
          <Panel className={'disabled'} header={<h1>Платежные терминалы</h1>} key="4">
            <section className={style.terminals}>
              <Table
                dataSource={this.dataSource}
                columns={this.columns}
                rowClassName={this.renderRow}
              />
            </section>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
