import * as React from 'react';
import { Modal } from 'antd';

import doc1 from 'assets/img/Свидетельство на ТЗ (Капелла).jpg';
import doc2 from 'assets/img/Свидетельство о внесении сведений в государственный реестр микрофинансовых организаций.jpg';
import doc3 from 'assets/img/Свидетельство о государственной регистрации юридического лица (МКК Капелла).jpg';
//import doc4 from 'assets/img/Свидетельство СРО (Народная опора).jpg';
import doc4 from 'assets/img/Свидетельство ООО МКК КАПЕЛЛА.jpg';
import logo from 'assets/img/logo_new.png';
import fileIcon from 'assets/img/fileIcon.png';
import docIcon from 'assets/img/docIcon.png';
import style from './style.module.css';
import $ from 'jquery';


export interface Info {
    visibleSendForm: any;
}

export class Info extends React.Component<Info, any> {
  static defaultProps: Partial<Info> = {
    visibleSendForm: false
  };

  constructor(props: Info, context?: any) {
    super(props, context);
    this.state = {
      visibleDocument: false,
      title: '',
      file: '',
      docsPanel: ''
    };
  }

  componentDidMount(): void {
    import("app/components/DocsPanel")
        .then(({ DocsPanel }) => {
          console.log('load DocsPanel');
          this.setState({
            docsPanel: DocsPanel
          })
        })
        .catch(err => {
          console.log(err)
          // Handle failure
        });
    $(document).ready(function() {
      // console.log('Ready!!!!');
      $('.sub').on('click', function(e) {
        e.preventDefault();
        $(this).next('ul').toggle();
      });
    });
  }

  docs = [
    {
      img: doc2,
      text: 'Свидетельство Банка России о внесении в реестр ЦБ'
    },
    {
      img: doc3,
      text: 'Свидетельство о регистрации юр лица ООО МКК «Капелла»'
    },
    {
      img: doc1,
      text: 'Свидетельство на товарный знак «Creditcar»'
    },
    {
      img: doc4,
      text: 'Свидетельство о членстве в Союзе «Микрофинансовый альянс «Институты развития малого бизнеса»'
    }
  ];

  anotherDocs = [
    [
      {
        text: 'Общие условия договора потребительского займа',
        url: '/files/new_docs/Общие условия договора потребительского займа.pdf',
        items: [],
      },
      {
        text: 'Общие условия потребительского займа “Архив предыдущих редакций”',
        items: [
          {
            subtext: 'Общие условия договора потребительского займа редакция №4 от 26.07.2021',
            suburl: '/files/new_docs/Общие условия договора потребительского займа редакция №4 от 26.07.2021.docx'
          },
          {
            subtext: 'Общие условия договора потребительского займа редакция №3 от 01.01.2020',
            suburl: '/files/new_docs/Общие условия договора потребительского займа редакция №3 от 01.01.2020.pdf'
          },
          {
            subtext: 'Общие условия договора потребительского займа редакция №2 от 01.07.2019',
            suburl: '/files/new_docs/Общие условия договора потребительского займа редакция №2 от 01.07.2019.pdf'
          },
          {
            subtext: 'Общие условия договора потребительского займа редакция №1 от 22.04.2019',
            suburl: '/files/new_docs/Общие условия договора потребительского займа редакция №1 от 22.04.2019.pdf'
          },
        ],
      },
      {
        text: 'Информация об условиях предоставления, использования и возврата потребительского займа',
        url: '/files/new_docs/Информация об условиях предоставления, успользования и возврата потребительского займа.pdf',
        items: [],
      },
      {
        text: 'Информация об условиях предоставления, использования и возврата потребительского займа “Архив предыдущих редакций”',
        items: [
          {
            subtext: 'Информация об условиях предоставления, успользования и возврата потребительского займа редакция №3 от 01.01.2020',
            suburl: '/files/new_docs/Информация об условиях предоставления, успользования и возврата потребительского займа редакция №3 от 01.01.2020.docx'
          },
          {
            subtext: 'Информация об условиях предоставления, успользования и возврата потребительского займа редакция №2 от 01.07.2019',
            suburl: '/files/new_docs/Информация об условиях предоставления, успользования и возврата потребительского займа редакция №2 от 01.07.2019.pdf'
          },
          {
            subtext: 'Информация об условиях предоставления, успользования и возврата потребительского займа редакция №1 от 22.04.2019',
            suburl: '/files/new_docs/Информация об условиях предоставления, успользования и возврата потребительского займа редакция №1 от 22.04.2019.pdf'
          },
        ],
      },
      {
        text: 'Правила предоставления микрозаймов',
        url: '/files/new_docs/Правила предоставления микрозаймов от 15.09.2023.pdf',
        items: [],
      },
      {
        text: 'Правила предоставления микрозаймов “Архив предыдущих редакций”',
        items: [
            {
                subtext: 'Правила предоставления микрозаймов от 23.03.2022',
                suburl: '/files/new_docs/Правила предоставления микрозаймов от 23.03.2022.pdf'
            },
          {
            subtext: 'Правила предоставления микрозаймов от 01.12.2021',
            suburl: '/files/new_docs/Правила предоставления микрозаймов от 01.12.2021.pdf'
          },
          {
            subtext: 'Правила предоставления микрозаймов от 01.01.2020',
            suburl: '/files/new_docs/Правила предоставления микрозаймов от 01.01.2020.docx'
          },
          {
            subtext: 'Правила предоставления микрозаймов от 01.07.2019',
            suburl: '/files/new_docs/Правила предоставления микрозаймов от 01.07.2019.pdf'
          },
          {
            subtext: 'Правила предоставления микрозаймов от 22.04.2019',
            suburl: '/files/new_docs/Правила предоставления микрозаймов от 22.04.2019.pdf'
          },
        ],
      },
      {
        text: 'Базовый стандарт совершения операций на финансовом рынке',
        url: '/files/new_docs/Базовый стандарт совершения операций на финансовом рынке.docx',
        items: [],
      },
      {
        text: 'Базовый стандарт по управлению рисками',
        url: '/files/new_docs/Базовый по управлению рисками.docx',
        items: [],
      },
      {
        text: 'Базовый стандарт защиты прав и интересов физических и юридических лиц',
        url: '/files/new_docs/Базовый стандарт защиты прав и интересов физических и юридических лиц.docx',
        items: [],
      },
    ],
    [
      {
        text: 'Устав ООО МКК “Капелла”',
        url: '/files/new_docs/Устав ООО МКК Капелла.pdf',
        items: [],
      },
      {
        text: 'Устав ООО МКК “Капелла” “Архив предыдущих редакций”',
        items: [
          {
            subtext: 'Устав от 26.11.2018',
            suburl: '/files/new_docs/Устав от 26.11.2018.pdf'
          },
          {
            subtext: 'Устав от 31.07.2018',
            suburl: '/files/new_docs/Устав от 31.07.2018.pdf'
          },
        ],
      },
      {
        text: 'Реквизиты ООО МКК “Капелла”',
        url: '/files/new_docs/Реквизиты ООО МКК Капелла.pdf',
        items: [],
      },
      {
        text: 'Информация о структуре и составе своих акционеров ООО МКК Капелла с 11.09.2024 г.',
        url: '/files/new_docs/Информация о структуре и составе своих акционеров ООО МКК  Капелла с 11.09.2024.pdf',
        items: [],
      },
      {
        text: 'Схема взаимосвязи общества и лиц ООО МКК Капелла с 11.09.2024 г.',
        url: '/files/new_docs/Схема взаимосвязи общества и лиц ООО МКК Капелла с 11.09.2024.pdf',
        items: [],
      },
      {
        text: 'Политика в отношении обработки персональных данных',
        url: '/files/new_docs/Политика в отношении обработки персональных данных.pdf',
        items: [],
      },
      {
        text: 'Памятка потребителей по урегулированию споров через финансового уполномоченного',
        url: '/files/new_docs/Памятка потребителей по урегулированию споров через финансового уполномоченного.pdf',
        items: [],
      },
      {
        text: 'Правила проведения маркетинговой (рекламой) акции “Приведи друга”',
        url: '/files/new_docs/Правила проведения акции приведи друга.pdf',
        items: [],
      },
      {
        text: 'Заявление о частично досрочном погашении займа',
        url: '/files/new_docs/Заявление_о_частично_досрочном_погашении_займа.docx',
        items: [],
      }
    ]
  ];

  clientsInfoTable = [
    {
      key: 'Банк России:',
      value: 'https://www.cbr.ru/',
      is_link: true
    },
    {
      key: 'Интернет-приемная Банка России:',
      value: 'https://www.cbr.ru/Reception/ ',
      is_link: true
    },
    {
      key: 'Государственный реестр микрофинансовых организаций:',
      value: 'https://www.cbr.ru/microfinance/registry/',
      is_link: true
    },
    {
      key: 'Регистрационный номер записи в государственном реестре микрофинансовых организаций: ',
      value: 'https://cbr.ru/finorg/foinfo/?ogrn=1162468123102',
      is_link: true
    },
    {
      key: 'Дата внесения сведений о юридическом лице в государственный реестр микрофинансовых организаций:',
      value: 'с 22.04.2019',
      is_link: false
    },
    {
      key: 'Обращения, связанные с кредитной историей, можно направить на электронную почту:',
      value: 'creditcar.info@gmail.com',
      is_link: false
    },
    {
      text: 'Информация о кредитных каникулах для участников специальной военной операции (СВО)',
      url: '/files/new_docs/Информация по кредитным каникулам для участников специальной военной операции (СВО).pdf',
    },
  ];

  clientsInfoTableLinks = [
    {
      text: 'Информация о кредитных каникулах для участников специальной военной операции (СВО)',
      url: '/files/new_docs/Информация по кредитным каникулам для участников специальной военной операции (СВО).pdf',
    },
  ];

  sroInfoTable = [
    {
      key: 'Компания:',
      value: 'ООО МКК «Капелла»',
      is_link: false
    },
    {
      key: 'Дата вступления:',
      value: 'с 24.05.2022',
      is_link: false
    },
    {
      key: 'Наименование СРО:',
      value: 'Союз «Микрофинансовый альянс «Институты развития малого бизнеса»',
      is_link: false
    },
      {
          key: 'Адрес:',
          value: 'г. Москва, Полесский проезд 16, стр.1, оф.308',
          is_link: false
      },
      {
          key: 'Телефон:',
          value: '8 (800) 555-24-99',
          is_link: false
      },
    {
      key: 'Официальный сайта СРО:',
      value: 'https://alliance-mfo.ru/',
      is_link: true
    },
  ];

  financeInfoTable = [
    {
      key: 'Место нахождения и почтовый адрес службы обеспечения деятельности финансового уполномоченного:',
      value: '119017, г. Москва, Старомонетный пер., дом 3',
      is_link: false
    },
    {
      key: 'Номер телефона службы обеспечения деятельности финансового уполномоченного:',
      value: '8 (800) 200-00-10',
      is_link: false
    },
    {
      key: 'Адрес официального сайта финансового уполномоченного в информационно-телекоммуникационной сети "Интернет":',
      value: 'https://finombudsman.ru/',
      is_link: true
    },
  ];

  showDocument = (title: string, file: any) => {
    this.setState({
      visibleDocument: true,
      title,
      file
    });
  };

  handleCancel = () => {
    this.setState({
      visibleDocument: false
    });
  };

  render() {
    let DocsPanel = this.state.docsPanel || (() => <div />);
    return (
      <div className={style.docs}>
        <div className={style.info}>
          <div>
            <img src={logo} alt="" />
            <div className={style.companyTitle}>Общество с ограниченной ответственностью Микрокредитная компания “КАПЕЛЛА”.</div>
          </div>
          <div>
            <p>ООО МКК «Капелла». Регистрационный номер записи в государственном реестре микрофинансовых организаций № 1903504009230, ОГРН 1162468123102, ИНН 2465156710</p>
            <p>Юр. адрес РФ, 660135, Красноярский Край, г. Красноярск, ул. Взлетная, д.5, стр.1, оф. 66</p>
            <p>Ген.дир. Манежина А.Е. дата назначения на должность: 15.09.2020 г.</p>
          </div>
        </div>
        <DocsPanel />
        <h2>Документы</h2>
        <div className={style.docsBlock}>
          {[0, 1].map((c, u) => (
            <ul className={style.docsList} key={u}>
              {this.anotherDocs[c].map((item, i) => {
                let {url = '', text = '', items = []} = item;
                if (items.length == 0) {
                  return (
                    <li key={i}>
                      <a
                        href={url}
                        className={url ? '' : 'disabled'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={docIcon} alt="" />
                        <span>{text}</span>
                      </a>
                    </li>
                  )
                }
                else {
                  return (
                    <li key={i}>
                      <a className="sub">
                        <img src={docIcon} alt="" />
                        <span>{text}</span>
                      </a>
                        <ul className={style.docsSubList}>
                          {items.map(({ suburl, subtext }, j) => (
                            <li key={j}>
                              <a
                                href={suburl}
                                className={suburl ? '' : 'disabled'}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src={docIcon} alt="" />
                                <span>{subtext}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                    </li>
                  )
                }
              })}
            </ul>
          ))}
        </div>
        <div className={style.infoListGroup}>
          <div className={style.infoListBlock}>
            <h1 className={style.clientsInfoTitle}>Информация для клиентов</h1>
            {/*<table className={style.infoTable}>
              {this.clientsInfoTable.map(({ key, value, is_link }, i) => (
                <tr key={i}>
                  <td>{key}</td>
                  {is_link ? <td><a href={value}>{value}</a></td> : <td>{value}</td>}
                </tr>
              ))}
            </table>*/}
            <ul className={style.infoList}>
              {this.clientsInfoTable.map(({ key, value, is_link }, i) => (
                <li key={i}>
                  <div>{key}</div>
                  {is_link ? <div><a href={value}>{value}</a></div> : <div>{value}</div>}
                </li>
              ))}
              {this.clientsInfoTableLinks.map(({ text, url }, i) => (
                  <li key={i}>
                    <div><a href={url}>{text}</a></div>
                  </li>
              ))}
            </ul>
          </div>
          <div className={style.infoListBlock}>
            <h1 className={style.sroInfoTitle}>Информация о членстве МФО в СРО</h1>
            {/*<table className={style.infoTable}>
              {this.sroInfoTable.map(({ key, value, is_link }, i) => (
                <tr key={i}>
                  <td>{key}</td>
                  {is_link ? <td><a href={value}>{value}</a></td> : <td>{value}</td>}
                </tr>
              ))}
            </table>*/}
            <ul className={style.infoList}>
              {this.sroInfoTable.map(({ key, value, is_link }, i) => (
                <li key={i}>
                  <div>{key}</div>
                  {is_link ? <div><a href={value}>{value}</a></div> : <div>{value}</div>}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={style.infoLastBlock}>
          <h1 className={style.financeInfoTitle}>Информация о финансовом уполномоченном:</h1>
          <p>Потребитель финансовых услуг вправе направить обращение финансовому уполномоченному в соответствии со статьями 15-19 Федерального закона от 4 июня 2018 года N 123-ФЗ "Об уполномоченном по правам потребителей финансовых услуг".</p>
          {/*<table className={style.infoTable}>
            {this.financeInfoTable.map(({ key, value, is_link }, i) => (
              <tr key={i}>
                <td>{key}</td>
                {is_link ? <td><a href={value}>{value}</a></td> : <td>{value}</td>}
              </tr>
            ))}
          </table>*/}
          <ul className={style.infoList}>
            {this.financeInfoTable.map(({ key, value, is_link }, i) => (
              <li key={i}>
                <div>{key}</div>
                {is_link ? <div><a href={value}>{value}</a></div> : <div>{value}</div>}
              </li>
            ))}
          </ul>
        </div>
        <div className={style.footNote}>
          <p>
            Условия займа под залог ПТС. Услуга предоставляется резидентам РФ, возраст от 21 до 72 лет.
            Минимальный срок займа 61 день, максимальный 36 месяцев.
            Максимальная сумма займа – до 1 000 000 рублей, минимальная процентная ставка – от 1,5% до 7% в месяц.
            Максимальная годовая процентная ставка за год составляет 85,167%.
            В случае просрочки начисляется неустойка (пени) из расчета 20% годовых (0,05479% в день) от суммы просроченной задолженности (основного долга) за каждый календарный день просрочки, начиная с даты, следующей за датой очередного не исполненного в срок платежа. Начисление процентов – ежемесячно. Наличие полиса ОСАГО и/или КАСКО – не обязательно.
            Досрочное погашение без штрафов и комиссий. Дополнительная комиссия за выдачу отсутствует.
            Пример: при сумме займа 50 000 рублей, годовой ставкой 85,167% на срок 2 года, ежемесячный платеж составит 4 396,83 рублей.
            Услуга предоставляется ООО МКК «Капелла», ОГРН 1162468123102, ИНН 2465156710, Регистрационный номер записи в государственном реестре микрофинансовых организаций № 1903504009230.
            660135, г. Красноярск ул. Взлетная, 5, стр1. офис 66.
            Обращаем ваше внимание, что информация, размещенная на сайте, носит информационный характер и не является публичной офертой, определяемой положениями статьи 437 Гражданского кодекса Российской Федерации. Конкретные условия займа будут определяться отдельно при заключении договора.
          </p>
        </div>
        <Modal
          visible={this.state.visibleDocument}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          className={style.modalDocument}
        >
          <div className={style.document}>
            <h1>{this.state.title}</h1>
            <img src={this.state.file} alt="" />
          </div>
        </Modal>
      </div>
    );
  }
}
