import * as React from 'react';
import { Modal } from 'antd';

import doc1 from 'assets/img/Свидетельство на ТЗ (Капелла).jpg';
import doc2 from 'assets/img/Свидетельство о внесении сведений в государственный реестр микрофинансовых организаций.jpg';
import doc3 from 'assets/img/Свидетельство о государственной регистрации юридического лица (МКК Капелла).jpg';
import doc4 from 'assets/img/Свидетельство СРО (Народная опора).jpg';
import logo from 'assets/img/info_logo.png';
import fileIcon from 'assets/img/fileIcon.png';
import docIcon from 'assets/img/docIcon.png';
import style from './style.module.css';


export interface Info {
    visibleSendForm: any;
}

export class Info extends React.Component<Info, any> {
  static defaultProps: Partial<Info> = {
    visibleSendForm: false
  };

  constructor(props: Info, context?: any) {
    super(props, context);
    this.state = {
      visibleDocument: false,
      title: '',
      file: '',
      docsPanel: ''
    };
  }

  componentDidMount(): void {
    import("app/components/DocsPanel")
        .then(({ DocsPanel }) => {
          console.log('load DocsPanel');
          this.setState({
            docsPanel: DocsPanel
          })
        })
        .catch(err => {
          console.log(err)
          // Handle failure
        });
  }

  docs = [
    {
      img: doc2,
      text: 'Свидетельство Банка России о внесении в реестр ЦБ'
    },
    {
      img: doc3,
      text: 'Свидетельство о регистрации юр лица ООО МКК «Капелла»'
    },
    {
      img: doc1,
      text: 'Свидетельство на товарный знак «Creditcar»'
    },
    {
      img: doc4,
      text: 'Свидетельство о членстве в СРО «МиР»'
    }
  ];

  anotherDocs = [
    {
      text: 'Общие условия договора потребительского займа',
      url: '/files/Общие_условия_договора_потребительского_займа_1.docx'
    },
    {
      text: 'Общие условия потребительского займа “Архив предыдущих редакций”',
      url: '/files/Общие_условия_договора_потребительского_займа_1.docx'
    },
    {
      text: 'Информация об условиях предоставления, использования и возврата потребительского займа',
      url: '/files/Общие_условия_договора_потребительского_займа_1.docx'
    },
    {
      text: 'Правила предоставления займов',
      url: '/files/Правила%20предоставления%20микрозаймов.pdf'
    },
    {
      text: 'Базовый стандарт совершения операций на финансовом рынке',
      url: '/files/Базовый%20стандарт.pdf'
    },
    {
      text: 'Базовый стандарт по управлению рисками',
      url: '/files/Базовый%20стандарт.pdf'
    },
    {
      text: 'Базовый стандарт защиты прав интересов физических лиц',
      url: '/files/Базовый%20стандарт.pdf'
    },
    {
      text: 'Свидетельство о государственной регистрации юридического лица',
      url: '/files/Политика%20в%20отношении%20обработки%20персональных%20данных.pdf'
    },
    {
      text: 'Свидетельство о внесении сведений в государственный реестр микрофинансовых организаций',
      url: '/files/Политика%20в%20отношении%20обработки%20персональных%20данных.pdf'
    },
    {
      text: 'Свидетельство СРО',
      url: '/files/Политика%20в%20отношении%20обработки%20персональных%20данных.pdf'
    },
    {
      text: 'Свидетельство на товарный знак',
      url: '/files/Политика%20в%20отношении%20обработки%20персональных%20данных.pdf'
    },
    {
      text: 'Устав',
      url: '/files/Политика%20в%20отношении%20обработки%20персональных%20данных.pdf'
    },
    {
      text: 'Устав “Архив предыдущих редакций”',
      url: '/files/Политика%20в%20отношении%20обработки%20персональных%20данных.pdf'
    },
    {
      text: 'Реквизиты ООО МК “Капелла”',
      url: '/files/Реквизиты%20ООО%20МКК%20Капелла.pdf'
    },
    {
      text: 'Информация о структуре и составе своих акционеров ООО МКК Капелла с 11.09.2024 г.',
      url: '/files/new_docs/Информация о структуре и составе своих акционеров ООО МКК  Капелла с 11.09.2024.pdf'
    },
    {
      text: 'Политика в отношении обработки персональных данных',
      url: '/files/Политика%20в%20отношении%20обработки%20персональных%20данных.pdf'
    },
    {
      text: 'Памятка потребителей по урегулированию споров через финансового уполномоченного',
      url: '/files/Памятка%20потребителей%20по%20урегулированию%20споров%20через%20финансового%20уполномоченного.pdf'
    },
    {
      text: 'Правила проведения маркетинговой (рекламой) акции “Приведи друга”',
      url: '/files/Правила%20проведения%20акции%20приведи%20друга.pdf'
    },
    {
      text: 'Заявление о частично досрочном погашении займа',
      url: '/files/Заявление_о_частично_досрочном_погашении_займа.docx'
    }
  ];

  clientsInfoTable = [
    {
      key: 'Банк России:',
      value: 'https://www.cbr.ru/',
      is_link: true
    },
    {
      key: 'Интернет-приемная Банка России:',
      value: 'https://www.cbr.ru/Reception/ ',
      is_link: true
    },
    {
      key: 'Государственный реестр микрофинансовых организаций:',
      value: 'https://www.cbr.ru/microfinance/registry/',
      is_link: true
    },
    {
      key: 'Регистрационный номер записи в государственном реестре микрофинансовых организаций: ',
      value: 'https://cbr.ru/finorg/foinfo/?ogrn=1162468123102',
      is_link: true
    },
    {
      key: 'Дата внесения сведений о юридическом лице в государственный реестр микрофинансовых организаций:',
      value: 'с 22.04.2019',
      is_link: false
    },
  ];

  sroInfoTable = [
    {
      key: 'Компания:',
      value: 'ООО МКК «Капелла»',
      is_link: false
    },
    {
      key: 'Дата вступления:',
      value: 'с 24.05.2022',
      is_link: false
    },
    {
      key: 'Наименование СРО:',
      value: 'Союз «Микрофинансовый альянс «Институты развития малого бизнеса»',
      is_link: false
    },
    {
      key: 'Адрес:',
      value: 'г. Москва, Полесский проезд 16, стр.1, оф.308',
      is_link: false
    },
    {
      key: 'Телефон:',
      value: '8 (800) 555-24-99',
      is_link: false
    },
    {
      key: 'Официальный сайта СРО:',
      value: 'https://alliance-mfo.ru/',
      is_link: true
    },
  ];

  financeInfoTable = [
    {
      key: 'Место нахождения и почтовый адрес службы обеспечения деятельности финансового уполномоченного:',
      value: '119017, г. Москва, Старомонетный пер., дом 3',
      is_link: false
    },
    {
      key: 'Номер телефона службы обеспечения деятельности финансового уполномоченного:',
      value: '8 (800) 200-00-10',
      is_link: false
    },
    {
      key: 'Адрес официального сайта финансового уполномоченного в информационно-телекоммуникационной сети "Интернет":',
      value: 'https://finombudsman.ru/',
      is_link: true
    },
  ];

  showDocument = (title: string, file: any) => {
    this.setState({
      visibleDocument: true,
      title,
      file
    });
  };

  handleCancel = () => {
    this.setState({
      visibleDocument: false
    });
  };

  render() {
    let DocsPanel = this.state.docsPanel || (() => <div />);
    return (
      <div className={style.docs}>
        <div className={style.info}>
          <div>
            <img src={logo} alt="" />
            <div className={style.companyTitle}>Общество с ограниченной ответственностью Микрокредитная компания “КАПЕЛЛА”.</div>
          </div>
          <div>
            <p>ООО МКК «Капелла». Регистрационный номер записи в государственном реестре микрофинансовых организаций № 1903504009230, ОГРН 1162468123102, ИНН 2465156710</p>
            <p>Юр. адрес РФ, 660135, Красноярский Край, г. Красноярск, ул. Взлетная, д.5, стр.1, оф. 66</p>
            <p>Ген.дир. Манежина А.Е. дата назначения на должность: 15.09.2020 г.</p>
          </div>
        </div>
        <DocsPanel />
        <h2>Документы</h2>
        <ul className={style.docsList}>
          {this.anotherDocs.map(({ url, text }, i) => (
            <li key={i}>
              <a
                href={url}
                className={url ? '' : 'disabled'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={docIcon} alt="" />
                <span>{text}</span>
              </a>
            </li>
          ))}
        </ul>
        <h2 className={style.clientsInfoTitle}>Информация для клиентов</h2>
        <table className={style.infoTable}>
          {this.clientsInfoTable.map(({ key, value, is_link }, i) => (
            <tr key={i}>
              <td>{key}</td>
              {is_link ? <td><a href={value}>{value}</a></td> : <td>{value}</td>}
            </tr>
          ))}
        </table>
        <h2 className={style.sroInfoTitle}>Информация о членстве МФО в СРО:</h2>
        <table className={style.infoTable}>
          {this.sroInfoTable.map(({ key, value, is_link }, i) => (
            <tr key={i}>
              <td>{key}</td>
              {is_link ? <td><a href={value}>{value}</a></td> : <td>{value}</td>}
            </tr>
          ))}
        </table>
        <h2 className={style.financeInfoTitle}>Информация о финансовом уполномоченном:</h2>
        <p>Потребитель финансовых услуг вправе направить обращение финансовому уполномоченному в соответствии со статьями 15-19 Федерального закона от 4 июня 2018 года N 123-ФЗ "Об уполномоченном по правам потребителей финансовых услуг".</p>
        <table className={style.infoTable}>
          {this.financeInfoTable.map(({ key, value, is_link }, i) => (
            <tr key={i}>
              <td>{key}</td>
              {is_link ? <td><a href={value}>{value}</a></td> : <td>{value}</td>}
            </tr>
          ))}
        </table>
        <div className={style.footNote}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </div>
        <Modal
          visible={this.state.visibleDocument}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          className={style.modalDocument}
        >
          <div className={style.document}>
            <h1>{this.state.title}</h1>
            <img src={this.state.file} alt="" />
          </div>
        </Modal>
      </div>
    );
  }
}
