import * as React from 'react';

import style from './style.module.css';
import phone from 'assets/img/phone.svg';
import clock from 'assets/img/clock.png';
import point from 'assets/img/point.png';
import metro_bot from 'assets/img/mosmetro_sign_bot.svg';
import metro_otr from 'assets/img/mosmetro_sign_otr.svg';
import metro from 'assets/img/address_blue_icon.png';
//import companyPoint from 'assets/img/companyPoint.png';
import Tabber from '../../Tabber';
//import pointer from 'assets/img/pointer2.png';

class Contacts extends React.Component<any, any> {
  [key: string]: any
  constructor(props: any) {
    super(props);
    this.contactsRef = React.createRef();
    this.state = {
      map: '',
      map2: ''
    };
    this.listener2 = this.listener2.bind(this)
  }

  componentDidMount() {
    this.listener2();
    document.addEventListener('scroll', this.listener2);
    document.addEventListener('touchmove', this.listener2);
  }

  componentWillUnmount(): void {
    document.removeEventListener('scroll', this.listener2);
    document.removeEventListener('touchmove', this.listener2)
  }

  listener2() {
    let getBodyScrollTop = function () {
      const el = document.scrollingElement || document.documentElement;
      return el.scrollTop
    };
    console.log('!this.state', this.state);
    if (!this.state.map) {
      console.log('!this.state.map', this.state.map);
      const elementRect = this.contactsRef.current.getBoundingClientRect();
      let elemOffset = Math.round(elementRect.top + document.body.getBoundingClientRect().top - elementRect.height);

      console.log(document.body.getBoundingClientRect());
      console.log(elementRect.height);
      console.log(getBodyScrollTop());
      console.log(elemOffset);
      if (getBodyScrollTop() > elemOffset) {
        import("app/components/Map2")
            .then(({ Map2 }) => {
              console.log('load Map2');
              console.log(Map2);
              this.setState({
                map2: Map2
              });
              document.removeEventListener('scroll', this.listener2);
              document.removeEventListener('touchmove', this.listener2)
            })
            .catch(err => {
              console.log(err)
              console.log('nothing')
              // Handle failure
            });
        import("app/components/Map")
            .then(({ Map }) => {
              console.log('load Map');
              this.setState({
                map: Map
              });
              document.removeEventListener('scroll', this.listener2);
              document.removeEventListener('touchmove', this.listener2)
            })
            .catch(err => {
              console.log(err)
              // Handle failure
            });
      }
    }
  };

  shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
    return this.state.map !== nextState.map || this.state.map2 !== nextState.map2
  }

  render() {
    let Map = this.state.map || (() => <div/>);
    let Map2 = this.state.map2 || (() => <div/>);
    let leftContentItem = (
      <section className={style.contactsItem} ref={this.contactsRef}>
        <div className={style.contactsInfo}>
          <article>
            <div className={style.contacts}>
              <div>
                <img src={phone} alt="" />
                <a href="tel:+78006009580">8-800-600-95-80</a>
              </div>
              <div>бесплатный звонок по РФ</div>
            </div>
            <div className={style.address}>
              <span> 2-й Муринский проспект, дом 36. стр.1 , пом.181Н</span>
              <div>
                ( <img src={metro} alt="" />
                Площадь мужества)
              </div>
            </div>
          </article>
          <article>
            <img src={clock} alt="" />
            <p>
              Пн-Пт: с 10:00 до 19:00
              <br />
              Сб-Вс: выходной
            </p>
          </article>
        </div>
        <Map/>
      </section>
    );

    let rightContentItem = (
        <section className={style.contactsItem} ref={this.contactsRef}>
          <div className={style.contactsInfo}>
            <article>
              <div className={style.contacts}>
                <div>
                  <img src={phone} alt="" />
                  <a href="tel:+78006009580">8-800-600-95-80</a>
                </div>
                <div>бесплатный звонок по РФ</div>
              </div>
              <div className={style.address}>
                <span className={style.nowrap}>Берёзовая аллея, 5Ас5</span>
                <div>
                  <img src={metro} alt="Ботанический сад" /> Ботанический сад — 1300 м<br />  <img src={metro} alt="Отрадное" />Отрадное — 2300 м
                </div>
              </div>
            </article>
            <article>
              <img src={clock} alt="" />
              <p>
                Пн-Пт: с 10:00 до 19:00
                <br />
                Сб-Вс: выходной
              </p>
            </article>
          </div>
          <Map2/>
        </section>
    );

    return (
      <div className={style.Contacts}>
        <Tabber
          leftHeaderText={'Санкт-Петербург'}
          rightHeaderText={'Москва'}
          leftContentItem={leftContentItem}
          rightContentItem={rightContentItem}
          customTabberClass={style.disableMoscow}
        />
      </div>
    );
  }
}

export default Contacts;
